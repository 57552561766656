import React from 'react';

import CustomerInfo from '../../components/Wholesalelib/customer/CustomerInfo';
const Customer = () => {
    return (
        <div>
            <CustomerInfo/>
        </div>
    );
}

export default Customer;
