import { Collapse } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { api_url } from '../../../config';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../utils/api';


const AddCustomer = ({ toggle, fetchCustomers }) => {
    const [empty, setEmpty] = useState(0)
    const [name, setName] = useState('')
    const [location, setLocation] = useState('')
    const [city, setCity] = useState('')
    const [town, setTown] = useState('')
    const [ghana_post, setGhana_post] = useState('')
    const [address, setAddress] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [regdate, setRegdate] = useState('')
    const [user, setUser] = useState('')
    const [customer_status, setCustomer_status] = useState('')
    const [selectedStatus, setSelectedStatus] = useState('');
    const status = ['All', 'Active', 'Inactive']
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [activeTab, setActiveTab] = useState('new');
    const [searchQuery,setSearchQuery] = useState('')
    const switchTab = (tab) => {
        setActiveTab(tab);
    };
    const { Panel } = Collapse;
    const [customers, setCustomers] = useState([])
    const { id, branch_id, token } = useSelector((state) => state.auth)



    const fetchInternalCustomers = async () => {
        setLoading(!loading)
        if(!navigator.onLine){
            toast("You're offline")
            return
           }
           const params = {
            search: `${searchQuery || ''}`,
        };
        try {
            const response = await api.get(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/contact/internal`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                params
            })
            setCustomers(response.data.data)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setError('Failed to fetch')

        }
    }

    const addCustomer = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const customerDetails = {
            name,
            city,
            email,
            phone,
            address,
            town,
            ghana_post

        }
        const required = !name || !email;
        if (required) {
            setError(`Please fill all required field`)
            setTimeout(() => {
                setError('');
            }, 6000);
            return
        }
        if(!navigator.onLine){
            toast("You're offline")
            return
           }
        try {
            setLoading(true)
            const response = await api.post(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/contact/external`, customerDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })
            toast.success("Customer added successfully")
            fetchCustomers()
            setName('')
            setPhone('')
            setEmail('')
            setGhana_post('')
            setCity('')
            setAddress('')
            setTown('')
            setCustomer_status('Customer added successfully')
            
            setTimeout(() => {
                toggle()
            }, 2000);
            setLoading(false)

        } catch (error) {
            toast.error(error?.response?.data?.error)
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);
            setLoading(false)


        } 
    }
    const addInternalCustomer = async (data) => {
        console.log(data);

        const customerDetails = {
            name: data.name,
            city: data.city,
            email: data.email,
            phone: data.phone,
            address: data.address,
            town: data.town,
            ghana_post: data.ghana_post

        }

        try {
            setLoading(true)
            const response = await api.post(api_url + `/api/v1/wholesaler/user/${id}/branch/${branch_id}/contact/${data.id}/internal`, customerDetails, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                }
            })

            fetchCustomers()
            console.log(response.data);

            setCustomer_status('Customer added successfully')
            setTimeout(() => {
                toggle()
            }, 2000);

        } catch (error) {
            setError(error?.response?.data?.error)
            setTimeout(() => {
                setError('');
            }, 6000);

        } finally {       
            setLoading(false)
        }
    }

    useEffect(() => {
        if (activeTab === 'existing') {
            fetchInternalCustomers()
        }
    }, [activeTab,searchQuery])
    return (
        <div className='max-w-2xl sm:min-h-max sm:max-h-min max-h-[500px] overflow-auto sm:min-w-[300px] lg:min-w-[600px] mx-auto p-4'>
            <div className='flex items-center justify-center font-lufga gap-2 sm:gap-4 sm:mx-4 mb-8'>
                <button
                    className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'new'
                        ? 'border-b-2 border-blue-500 text-blue-500'
                        : 'text-gray-500'
                        }`}
                    onClick={() => switchTab('new')}
                >
                    Create New Customer
                </button>
                <button
                    className={`text-xs sm:text-sm cursor-pointer py-2 px-4 ${activeTab === 'existing'
                        ? 'border-b-2 border-blue-500 text-blue-500'
                        : 'text-gray-500'
                        }`}
                    onClick={() => switchTab('existing')}
                >
                    Add an existing customer
                </button>

            </div>
            {/* {loading && ( // Display loader while loading
                <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                    <InfinitySpin color="#008DDA" loading={loading} size={36} />
                </div>
            )} */}

            {activeTab == 'new' && (
                <div>
                    <form className="grid gap-4 mb-6 md:grid-cols-2">
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Fullname<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                                placeholder="Enter name"
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                placeholder="Enter email"
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Contact</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => { setPhone(e.target.value) }}
                                placeholder="customer's contact"
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga">City</label>
                            <input
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga">Town</label>
                            <input
                                type="text"
                                value={town}
                                onChange={(e) => setTown(e.target.value)}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"

                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Address</label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => { setAddress(e.target.value) }}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" >Ghana Post</label>
                            <input
                                type="text"
                                value={ghana_post}
                                onChange={(e) => { setGhana_post(e.target.value) }}
                                placeholder=""
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                required
                            />
                        </div>
                    </form>
                    <div className='flex justify-between items-center'>
                        <div>
                            {customer_status ? <div>
                                <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{customer_status}</p>
                            </div> : error ? <div>
                                <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                                    {typeof error === 'string' ? error : (
                                        Object.keys(error).map((key) => (
                                            Array.isArray(error[key]) ? error[key][0] : error[key]
                                        ))
                                    )}
                                </p>
                            </div> : null}</div>

                        <button onClick={addCustomer} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Add Customer</button>

                    </div>
                </div>
            )
            }
            {activeTab == 'existing' && (
                <div className=''>
                    <div className='flex flex-col justify-center gap-2 mb-4'>
                        <input onChange={(e)=>(setSearchQuery(e.target.value))} className='w-full h-10 border border-slate-300 text-xs rounded-md focus:outline-none p-2' placeholder='Search for customer by any attribute' />
                    </div>
                    {loading ? ( // Display loader while loading
                        <div className="flex justify-center h-[35vh] items-center ">
                            <InfinitySpin color="#008DDA" loading={loading} size={36} />
                        </div>
                    ) : <div className='max-h-[35vh] overflow-auto'>

                        {/* <Collapse accordion>
                            {customers.map((customer) => (
                                <Panel header={customer.name } key={customer.id} className='hover:bg-slate-200 bg-slate-100'>
                                    <p>Email: {customer.email}</p>
                                    <p>Phone: {customer.phone}</p>
                                    <p>City: {customer.city}</p>
                                    <p>Town: {customer.town}</p>
                                    <p>Address: {customer.address}</p>
                                    <p>Ghana Post: {customer.ghana_post}</p>
                                    <p>Status: {customer.status}</p>
                                    <div className='flex justify-end'>
                                        <button onClick={() => { addInternalCustomer(customer) }} className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-sm shadow-md text-xs">Add customer</button>
                                    </div>
                                </Panel>
                            ))}
                        </Collapse> */}
                        <Collapse accordion items={customers.map((customer) => ({
                            key: customer.id,
                            label: customer.name,
                            children: (
                                <div>
                                    <p>Email: {customer.email}</p>
                                    <p>Phone: {customer.phone}</p>
                                    <p>City: {customer.city}</p>
                                    <p>Town: {customer.town}</p>
                                    <p>Address: {customer.address}</p>
                                    <p>Ghana Post: {customer.ghana_post}</p>
                                    <p>Status: {customer.status}</p>
                                    <div className='flex justify-end'>
                                        <button onClick={() => { addInternalCustomer(customer) }} className="bg-blue-500  hover:opacity-90 text-white py-1 px-4 rounded-sm shadow-md text-xs">Add customer</button>
                                    </div>
                                </div>
                            ),
                            className: 'hover:bg-slate-200 bg-slate-100',
                        }))} />
                       
                    </div>}
                    <div className='flex justify-center items-center mt-4'>
                        <div>
                            {customer_status ? <div>
                                <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{customer_status}</p>
                            </div> : error ? <div>
                                <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                                    {typeof error === 'string' ? error : (
                                        Object.keys(error).map((key) => (
                                            Array.isArray(error[key]) ? error[key][0] : error[key]
                                        ))
                                    )}
                                </p>
                            </div> : null}</div>

                    </div>

                </div>
            )}



        </div>
    );
}

export default AddCustomer;
