import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiOutlineAdjustments, HiOutlineChartBar, HiOutlineCube, HiOutlineDocumentText, HiOutlineFolderAdd, HiOutlineReceiptRefund, HiOutlineUsers, HiOutlineViewGrid } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../Redux/permissionSlice';
import { selectRoles } from '../../Redux/AuthSlice';
import { IoCartOutline } from 'react-icons/io5';
import { MdOutlineInventory, MdOutlineSell } from 'react-icons/md';

const Sidebar = () => {
    // const [activeMenuItem, setActiveMenuItem] = useState('');
    // const userRoles = useSelector((state)=>state.auth.roles);
    const location = useLocation()


    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    // const handleMenuItemClick = (menuItem, requiredRoles) => {
    //     setActiveMenuItem(menuItem);
    //     // sessionStorage.setItem('requiredRoles', JSON.stringify(requiredRoles));
    // };

    const getActiveMenuItem = (path) => {
        if (path.includes('/wholesale/dashboard')) return 'Dashboard';
        if (path.includes('/wholesale/summary')) return 'Summary';
        if (path.includes('/wholesale/suppliers')) return 'Suppliers';
        if (path.includes('/wholesale/retailers')) return 'Customers';
        if (path.includes('/wholesale/customers')) return 'Customers';
        if (path.includes('/wholesale/orders')) return 'Orders';
        if (path.includes('/wholesale/inventory')) return 'Inventories';
        if (path.includes('/wholesale/stocks')) return 'Stocks';
        if (path.includes('/wholesale/sales')) return 'Sales';
        if (path.includes('/wholesale/users')) return 'Users';
        return '';
    };

    const activeMenuItem = getActiveMenuItem(location.pathname);


    return (
        <div className='w-full h-full overflow-y-auto'>
            <div className='flex-col mb-8'>
                <p className='text-neutral-200 p-2 cursor-pointer bg-blue-700 shadow-lg font-semibold text-center tracking-widest'>WHOLESALE</p>
            </div>

            <div className='flex flex-col gap-1 '>
                {hasPermission(userRoles, ['executiveViewer']) && (
                    <Link to='/wholesale/dashboard'
                        state={{ requiredRoles: ['executiveViewer'] }}

                    >
                        <div
                            className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Dashboard' ? 'bg-blue-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Dashboard')}
                        >
                            <HiOutlineViewGrid size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Dashboard' ? 'text-[#CAF4FF]' : ''}`}>Dashboard</button>
                        </div>
                    </Link>
                )}

                <Link to='/wholesale/summary'>
                    <div
                        className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Summary' ? 'bg-blue-900' : ''}`}
                    // onClick={() => handleMenuItemClick('Summary')}
                    >
                        <HiOutlineCube size={20} className='text-white' />
                        <button className={`text-white ${activeMenuItem === 'Summary' ? 'text-[#CAF4FF]' : ''}`}>Summary</button>
                    </div>
                </Link>

                {hasPermission(userRoles, ['supplierViewer', 'supplierWriter', 'supplierAdmin']) && (
                    <Link to='/wholesale/suppliers'
                        state={{ requiredRoles: ['supplierViewer', 'supplierWriter', 'supplierAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Suppliers' ? 'bg-blue-900 ' : ''}`}
                        // onClick={() => handleMenuItemClick('Suppliers')}
                        >
                            <HiOutlineAdjustments size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Suppliers' ? 'text-[#CAF4FF]' : ''}`}>Suppliers</button>
                        </div>
                    </Link>
                )}
                {hasPermission(userRoles,['retailerViewer', 'retailerWriter', 'retailerAdmin']) && (
                    <Link to='/wholesale/customers'
                        state={{ requiredRoles: ['retailerViewer', 'retailerWriter', 'retailerAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Customers' ? 'bg-blue-900 ' : ''}`}
                        // onClick={() => handleMenuItemClick('Suppliers')}
                        >
                            <MdOutlineSell size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Customers' ? 'text-[#CAF4FF]' : ''}`}>Customers</button>
                        </div>
                    </Link>
                )}

                {/* {hasPermission(userRoles, ['retailerViewer', 'retailerWriter', 'retailerAdmin']) && (
                    <Link to='/wholesale/retailers'
                        state={{ requiredRoles: ['retailerViewer', 'retailerWriter', 'retailerAdmin'] }}
                    >
                        <div
                            className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Customers' ? 'bg-blue-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Customers')}
                        >
                            <MdOutlineSell size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Customers' ? 'text-[#CAF4FF]' : ''}`}>Customers</button>
                        </div>
                    </Link>
                )} */}

                {hasPermission(userRoles, ['orderViewer', 'orderWriter','orderAdmin']) && (
                    <Link to='/wholesale/orders'
                        state={{ requiredRoles: ['orderViewer', 'orderWriter', 'orderAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Orders' ? 'bg-blue-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Orders')}
                        >
                            <IoCartOutline size={20} className='text-white'  />
                            <button className={`text-white ${activeMenuItem === 'Orders' ? 'text-[#CAF4FF]' : ''}`}>Orders</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['inventoryViewer', 'inventoryWriter', 'inventoryAdmin']) && (
                    <Link to='/wholesale/inventory'
                        state={{ requiredRoles: ['inventoryViewer', 'inventoryWriter', 'inventoryAdmin'] }}

                    >
                        <div
                            className={`flex items-center  gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Inventories' ? 'bg-blue-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Inventories')}
                        >
                            <MdOutlineInventory size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Inventories' ? 'text-[#CAF4FF]' : ''}`}>Inventories</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['stockViewer', 'stockWriter', 'stockAdmin']) && (
                    <Link to='/wholesale/stocks'
                        state={{ requiredRoles: ['stockViewer', 'stockWriter', 'stockAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Stocks' ? 'bg-blue-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Stocks')}
                        >
                            <HiOutlineChartBar size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Stocks' ? 'text-[#CAF4FF]' : ''}`}>Stocks</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['transactionViewer', 'transactionWriter', 'transactionAdmin']) && (
                    <Link to='/wholesale/sales'
                        state={{ requiredRoles: ['transactionViewer', 'transactionWriter', 'transactionAdmin'] }}

                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Sales' ? 'bg-blue-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Sales')}
                        >
                            <HiOutlineDocumentText size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Sales' ? 'text-[#CAF4FF]' : ''}`}>Sales</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['userViewer', 'userWriter', 'userAdmin']) && (
                    <Link to='/wholesale/users'
                        state={{ requiredRoles: ['userViewer', 'userWriter', 'userAdmin'] }}

                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Users' ? 'bg-blue-900 ' : ''}`}
                        // onClick={() => handleMenuItemClick('Users')}
                        >
                            <HiOutlineUsers size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Users' ? 'text-[#CAF4FF]' : ''}`}>Users</button>
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
