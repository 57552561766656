import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { FcCancel } from 'react-icons/fc';
import { GrEdit } from 'react-icons/gr';
import { InfinitySpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { api_url } from '../../../config';
import axios from 'axios';
import { hasPermission } from '../../../Redux/permissionSlice';
import { selectRoles } from '../../../Redux/AuthSlice';
import api from '../../../utils/api';

const CustomerDetail = ({ customer, customers, fetchCustomers }) => {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(customer?.name)
    const [city, setCity] = useState(customer?.city)
    const [town, setTown] = useState(customer?.town)
    const [address, setAddress] = useState(customer?.address)
    const [email, setEmail] = useState(customer?.email)
    const [contact, setContact] = useState(customer?.phone)
    const [status, setStatus] = useState('')
    const [customer_status, setCustomer_status] = useState('')
    const [error, setError] = useState('')
    const [ghana_post, setGhana_post] = useState(customer?.ghana_post)
    const [editMode, setEditMode] = useState(false);

    const checker = customer?.status === 'active'
    const { id, branch_id, token } = useSelector((state) => state.auth);
    const selectedRoles = useSelector(selectRoles);
    const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    const handleEditMode = () => {
        // Toggle edit mode
        setEditMode(!editMode);

        // If entering edit mode, set the form fields to the current customer's details
        // if (!editMode) {
        //     setName(customer?.name || '');
        //     setContact(customer?.phone || '');
        //     setEmail(customer?.email || '');
        //     setCity(customer?.city || '');
        //     setTown(customer?.town || '');
        //     setAddress(customer?.address || '');
        //     setGhana_post(customer?.ghana_post || '');
        //     setStatus(customer?.status || '');
        // }
    };

    useEffect(() => {
        // Update form fields with the new customer's data
        setName(customer?.name || '');
        setContact(customer?.phone || '');
        setEmail(customer?.email || '');
        setCity(customer?.city || '');
        setTown(customer?.town || '');
        setAddress(customer?.address || '');
        setGhana_post(customer?.ghana_post || '');
        setStatus(customer?.status || '');
    }, [customer, editMode]);

    const handleUserStatus = (checked) => {
        const status = checked ? 'active' : 'inactive';
        setStatus(status);
    }

    const editCustomer = async () => {
        setLoading(true);

        try {
            const updatedCustomer = {
                name,
                email,
                city,
                town,
                address,
                phone: contact,
                ghana_post,
                status,
            };

            const response = await api.put(
                `${api_url}/api/v1/wholesaler/user/${id}/branch/${branch_id}/contact/${customer.id}/external`,
                updatedCustomer,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Handle successful response
            console.log(response.data);
            setCustomer_status('Customer updated successfully');
            setError('');
            fetchCustomers(1, 50, '')
            setTimeout(() => {
                setCustomer_status('');
            }, 3000);

        } catch (error) {
            // Handle error
            console.error(error);
            if (error.response && error.response.data) {
                setError(error.response.data);
            } else {
                setError('An error occurred while updating the customer');
            }
        } finally {
            setLoading(false);

        }
    };


    return (
        <div>
            <div className='font-lufga'>
                {loading && ( // Display loader while loading
                    <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                        <InfinitySpin color="#008DDA" loading={loading} size={40} />
                    </div>
                )}
                <div>
                    {customer?.status === "archived" || customer.on_pharmtrix ? null : <div className=' mb-6 flex flex-col sm:flex-row justify-between  items-center '>
                        <div className='flex items-center gap-4 my-1'>
                            {hasPermission(roles, ['retailWriter', 'retailAdmin']) &&
                                <button onClick={handleEditMode} className='font-lufga text-center font-semibold text-neutral-500 p-2 rounded-md shadow-md flex items-center gap-1 hover:bg-neutral-200 '>   {!editMode ? <><GrEdit /><span className='text-xs'>Edit</span></> : <><FcCancel /><span className='text-xs'>Cancel</span></>} </button>
                            }
                            {/* <button onClick={resetPassword} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>   <MdLockReset /><span className='text-xs'>Reset Password</span> </button>
                                    <button onClick={() => setStatus_history(!status_history)} className='font-lufga text-center text-xs font-semibold text-white p-2 rounded-md shadow-md flex items-center gap-1 bg-blue-500 hover:bg-blue-600 '>  <MdHistoryToggleOff /><span className='text-xs'>status history</span> </button> */}
                        </div>
                        <div className='flex items-center gap-3 '>
                            <label className="text-sm font-lufga" >Status</label>
                            <Switch

                                checkedChildren="active"
                                unCheckedChildren="inactive"
                                defaultChecked={checker}
                                onChange={handleUserStatus}
                                disabled={!editMode}

                            />
                        </div>
                    </div>}
                    <form className="grid gap-4 mb-6 md:grid-cols-2">
                        {loading && ( // Display loader while loading
                            <div className="flex justify-center items-center w-full h-full bg-gradient-to-br from-opacity-40 to-opacity-60 backdrop-blur-sm absolute top-0 left-0">
                                <InfinitySpin color="#008DDA" loading={loading} size={36} />
                            </div>
                        )}

                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="name">Name<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={editMode ? name : customer?.name}
                                onChange={(e) => { setName(e.target.value) }}
                                placeholder="Enter customer's name"
                                required
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                            />

                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1">Email<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                value={editMode ? email : customer?.email}
                                onChange={(e) => { setEmail(e.target.value) }}
                                placeholder="Enter customer's email"
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1">City<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                value={editMode ? city : customer?.city}
                                onChange={(e) => { setCity(e.target.value) }}
                                placeholder="Enter customer's email"
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Town</label>
                            <input
                                type="text"
                                value={editMode ? town : customer?.town}
                                onChange={(e) => { setTown(e.target.value) }}
                                placeholder=""
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1">Address<span className='font-serif text-red-400'>*</span></label>
                            <input
                                type="text"
                                value={editMode ? address : customer?.address}
                                onChange={(e) => { setAddress(e.target.value) }}
                                placeholder="Enter customer's email"
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="address">Ghana Post</label>
                            <input
                                type="text"
                                value={editMode ? ghana_post : customer?.ghana_post}
                                onChange={(e) => { setGhana_post(e.target.value) }}
                                placeholder=""
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                                required
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Phone Number</label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={editMode ? contact : customer?.phone}
                                onChange={(e) => { setContact(e.target.value) }}
                                placeholder="Enter phone number"
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={!editMode}
                            />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <label className="text-sm font-lufga flex items-center gap-1" htmlFor="phone">Date Created </label>
                            <input
                                type="text"
                                id="phone"
                                name="phone"
                                value={new Date(customer?.created_at).toLocaleDateString()}
                                placeholder="Enter phone number"
                                className={`px-3 py-2  rounded-md focus:outline-none focus:border-blue-500 ${editMode ? 'border border-gray-300' : ''}`}
                                disabled={true}
                            />
                        </div>



                    </form>

                    <div className='flex justify-between items-center'>

                        {editMode && <button onClick={editCustomer} className="bg-blue-500 font-lufga text-white py-2 px-4 rounded-md hover:bg-blue-600 shadow-md transition duration-300">Update</button>}
                        {customer_status ? <div>
                            <p className="bg-green-300 font-lufga rounded-md shadow-md px-2 py-1 text-xs text-center ">{customer_status}</p>
                        </div> : error ? <div>
                            <p className="bg-red-300 font-lufga rounded-md text-center shadow-md px-2 py-1 text-sm ">
                                {typeof error === 'string' ? error : (
                                    Object.keys(error).map((key) => (
                                        Array.isArray(error[key]) ? error[key][0] : error[key]
                                    ))
                                )}
                            </p>
                        </div> : null}</div>
                </div>


            </div>
        </div>
    );
}

export default CustomerDetail;
