import React from 'react';

const PrescriptionPage = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-white  to-blue-50 flex flex-col items-center justify-center p-4">
            {/* Construction Icon */}
            <div className="relative mb-8 animate-bounce">
                <div className="w-16 h-16 bg-yellow-400 rounded-full flex items-center justify-center">
                    <svg
                        className="w-10 h-10 text-yellow-900"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 21l-7-5-7 5V5a2 2 0 012-2h10a2 2 0 012 2v16z"
                        />
                    </svg>
                </div>
                <div className="absolute -top-2 -right-2 w-6 h-6 bg-blue-500 rounded-full flex items-center justify-center animate-pulse">
                    <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                </div>
            </div>

            {/* Main Text */}
            <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4 text-center">
                Coming Soon!
            </h1>

            {/* Construction Banner */}
            <div className="bg-yellow-400 transform -rotate-3 px-6 py-2 mb-8">
                <span className="text-xl font-bold text-yellow-900">
                    🚧 Under Construction 🚧
                </span>
            </div>

            {/* Description */}
            <p className="text-gray-600 text-center max-w-md mb-8">
                We're working hard to bring you an amazing prescription management experience.
                Stay tuned for updates!
            </p>

            {/* Progress Bar */}
            <div className="w-64 md:w-96 h-3 bg-gray-200 rounded-full mb-8 overflow-hidden">
                <div
                    className="h-full bg-blue-500 rounded-full animate-pulse"
                    style={{ width: '70%' }}
                ></div>
            </div>

            {/* Features Coming Soon */}
            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center max-w-4xl">
                <div className="p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
                    <div className="text-2xl mb-2">💊</div>
                    <h3 className="font-semibold mb-1">Prescription Management</h3>
                    <p className="text-sm text-gray-600">Coming Soon</p>
                </div>
                <div className="p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
                    <div className="text-2xl mb-2">📊</div>
                    <h3 className="font-semibold mb-1">Analytics Dashboard</h3>
                    <p className="text-sm text-gray-600">Coming Soon</p>
                </div>
                <div className="p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
                    <div className="text-2xl mb-2">🔔</div>
                    <h3 className="font-semibold mb-1">Smart Reminders</h3>
                    <p className="text-sm text-gray-600">Coming Soon</p>
                </div>
            </div> */}

        </div>
    );
};

export default PrescriptionPage;